import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="epic-skills"></a><h2>Epic Skills</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">
In Alphabetical Order</span></h2>
    <table className="monster-index" border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="top">
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#appraise">Appraise</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#balance">Balance</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#bluff">Bluff</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#climb">Climb</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#concentration">Concentration</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#craft">Craft</a> </td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#decipher-script">Decipher Script</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#diplomacy">Diplomacy</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#disable-device">Disable
Device</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#disguise">Disguise</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#escape-artist">Escape
Artist</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#forgery">Forgery</a> </td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#gather-information">Gather
Information</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#handle-animal">Handle
Animal</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#heal">Heal</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#hide">Hide</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#intimidate">Intimidate</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#jump">Jump</a> </td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#knowledge">Knowledge</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#listen">Listen</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#move-silently">Move
Silently</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#open-lock">Open
Lock</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#perform">Perform</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#profession">Profession</a>
          </td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#ride">Ride</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#search">Search</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#sense-motive">Sense
Motive</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#sleight-of-hand">Sleight
of Hand</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#spellcraft">Spellcraft</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#spot">Spot</a>
          </td>
          <td style={{
            "width": "12%"
          }} valign="top">
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#survival">Survival</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#swim">Swim</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#tumble">Tumble</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#use-magic-device">Use Magic
Device</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#use-rope">Use
Rope</a>
          </td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#autohypnosis">Autohypnosis</a>
            <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#stabilize-self">Stabilize self</a>
          </td>
        </tr>
      </tbody>
    </table>
    <p>Despite
the near-magical nature of some epic level skill uses,
all uses of skills are considered exceptional abilities (except if
noted otherwise), and thus function normally even within areas of
antimagic. </p>
    <a id="epic-skill-synergy"></a><h6>Skill Synergy </h6>
    <p>Many skills are noted as
granting a synergy bonus to the use
of another skill when a character has 5 or more ranks in the first
skill. This synergy bonus increases by +2 for every additional 20 ranks
the character has in the skill. </p>
    <a id="epic-skill-descriptions"></a><h5>SKILL DESCRIPTIONS </h5>
    <p>This section describes
new DCs and new modifiers for skills. </p>
    <h6><a id="appraise"></a>Appraise </h6>
    <p>The character can sense
magical auras in objects.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Detect magic
          </td>
          <td>50
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Detect Magic:</span>
The
character can sense if an item has a magical aura. He or she can then
use Spellcraft to learn more about the item as if he or she had already
cast detect magic on the item. This requires a full-round action. </p>
    <h6><a id="balance"></a>Balance</h6>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Surface
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>1-2 Inches wide
          </td>
          <td>20
          </td>
        </tr>
        <tr>
          <td>Up to 1 inch wide
          </td>
          <td>40
          </td>
        </tr>
        <tr className="odd-row">
          <td>Hair-thin
          </td>
          <td>60
          </td>
        </tr>
        <tr>
          <td>Liquid*
          </td>
          <td>90
          </td>
        </tr>
        <tr className="odd-row">
          <td>Cloud
          </td>
          <td>120
          </td>
        </tr>
        <tr>
          <td rowSpan="1" colSpan="2"><span style={{
              "fontStyle": "italic"
            }}>*Includes any other
surface </span>
            <span style={{
              "fontStyle": "italic"
            }}>that
couldn&rsquo;t support the </span>
            <span style={{
              "fontStyle": "italic"
            }}>character&rsquo;s
weight, such
as </span>
            <span style={{
              "fontStyle": "italic"
            }}>a
fragile branch. </span>
          </td>
        </tr>
      </tbody>
    </table>
    <h6><a id="bluff"></a>Bluff </h6>
    <p>The character can implant
a nonmagical suggestion in a target,
display a false alignment, or disguise his or her surface thoughts. </p>
    <table style={{
      "height": "118px",
      "width": "360px"
    }} border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "150px"
          }}>Example
Circumstance
          </th>
          <th style={{
            "width": "150px"
          }}>Sense
Motive Modifier
          </th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "150px"
          }}>Instill
suggestion in target </td>
          <td style={{
            "width": "150px"
          }}>+50
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "150px"
          }}><span style={{
              "fontWeight": "bold"
            }}>Task</span>
          </td>
          <td style={{
            "width": "150px"
          }}><span style={{
              "fontWeight": "bold"
            }}>DC</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "150px"
          }}>Display
false alignment </td>
          <td style={{
            "width": "150px"
          }}>70
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "150px"
          }}>Disguise
surface thoughts </td>
          <td style={{
            "width": "150px"
          }}>100
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Instill Suggestion in
Target:</span>
This is identical to the effect of the suggestion spell, except that it
is nonmagical and lasts for only 10 minutes. It can be sensed as if it
were an enchantment effect (Sense Motive DC 25). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Display False Alignment:</span>
The character can fool alignment-sensing effects by displaying a false
alignment of his or her choice. Once set, a false alignment remains as
long as the character remains conscious and awake. Setting or changing
a false alignment requires a full-round action. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Disguise Surface Thoughts:</span>
The character can fool spells such as detect thoughts (or similar
effects) by displaying false surface thoughts. While the character
can&rsquo;t completely mask the presence of his or her thoughts, he
or she
can change his or her apparent Intelligence score (and thus the
character&rsquo;s apparent mental strength) by as much as 10 points
and can
place any thought in his or her &ldquo;surface thoughts&rdquo;
to be read by such
spells or effects. If a character attempts to use Sense Motive to
detect his or her surface thoughts (see the Sense Motive skill
description), this becomes an opposed check (though any result lower
than 100 automatically fails). </p>
    <h6><a id="climb"></a>Climb </h6>
    <p>The character can climb
otherwise unclimbable surfaces.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Surface
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>A perfectly
smooth, flat, vertical surface </td>
          <td>70
          </td>
        </tr>
        <tr>
          <td>A perfectly
smooth, flat, overhang or ceiling </td>
          <td>100
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Rapid Climbing:</span>
A
character can climb his or her speed as a move-equivalent action, or
double his or her speed as a full-round action (requiring two Climb
checks), but the character takes a -20 penalty on his or her check. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span>
The Legendary
Climber feat allows a character to ignore any penalties for accelerated
or rapid climbing. </p>
    <h6><a id="concentration"></a>Concentration </h6>
    <p>The character can cast
spells with somatic components even
while grappled.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Cast spell with
somatic component while grappled </td>
          <td>50 + spell level </td>
        </tr>
      </tbody>
    </table>
    <h6><a id="craft"></a>Craft </h6>
    <p>The character can craft
items more quickly than normal.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Quick creation </td>
          <td>+10 or more to DC </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Quick Creation:</span>
A
character can voluntarily increase the DC of crafting an item by any
multiple of 10. This allows the character to create an item more
quickly (since he or she will be multiplying this higher DC by his or
her skill check result to determine progress). The character must
decide the increase to the DC before making the check. </p>
    <h6>Craft (Alchemy)</h6>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "150px"
          }}>DC
(or DC Modifier) </th>
        </tr>
        <tr className="odd-row">
          <td>Create augmented
substance </td>
          <td style={{
            "width": "150px"
          }}>+20
or more to DC </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Create Augmented
Alchemical
Item or Substance:</span> This
requires the Augmented Alchemy feat, and
allows a character to create alchemical items and substances of greater
power than normal. To augment an alchemical substance, add +20 to the
DC required to create the item and multiply the cost by 5. If the item
or substance deals damage, double the damage dealt. If the item or
substance doesn&rsquo;t deal damage, double the duration of its
effect. If
the item or substance doesn&rsquo;t deal damage and
doesn&rsquo;t have a specific
listed duration (or has an instantaneous duration), double all
dimensions of its area. If the item or substance doesn&rsquo;t fit
any of
these categories, then it cannot be improved in this manner. A
character can create an item with multiple degrees of augmentation. For
every additional multiplier applied to damage, duration, or area, add
an additional +20 to the DC and add an additional 5 to the cost
multiplier.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Item
          </th>
          <th style={{
            "width": "100px"
          }}>Effect
of Augmenting </th>
        </tr>
        <tr className="odd-row">
          <td>Acid
          </td>
          <td>Direct hit 2d6,
splash 2 </td>
        </tr>
        <tr>
          <td>Alchemist's fire
          </td>
          <td>Direct hit 2d6,
splash 2 </td>
        </tr>
        <tr className="odd-row">
          <td>Antitoxin
          </td>
          <td>Duration 2 hours</td>
        </tr>
        <tr>
          <td>Smokestick
          </td>
          <td>Smoke fills a
20-foot cube </td>
        </tr>
        <tr className="odd-row">
          <td>Sunrod
          </td>
          <td>Glows for 12 hours </td>
        </tr>
        <tr>
          <td>Tanglefoot bag
          </td>
          <td>Becomes brittle
and fragile after 20 minutes </td>
        </tr>
        <tr className="odd-row">
          <td>Tinderwig
          </td>
          <td>No effect </td>
        </tr>
      </tbody>
    </table>
    <h6><a id="decipher-script"></a>Decipher Script </h6>
    <p>The character can
decipher magic scrolls.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Decipher a written
spell (such as a scroll) without
using read magic. One try per day. </td>
          <td>50 + 5 times spell
level </td>
        </tr>
      </tbody>
    </table>
    <h6><a id="diplomacy"></a>Diplomacy </h6>
    <p>The character can turn a
person into a fanatic follower. Refer
to the accompanying table.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>
          </th>
          <th rowSpan="1" colSpan="6" style={{
            "width": "100px"
          }}>------New Attitude
------ </th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "fontWeight": "bold"
          }}>Initial
Attitude </td>
          <td style={{
            "fontWeight": "bold"
          }}>Hos
          </td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Unf
          </td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Indif
          </td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Friend
          </td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Help 
          </td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Fanatic
          </td>
        </tr>
        <tr>
          <td>Hostile
          </td>
          <td>Less than 20 </td>
          <td style={{
            "verticalAlign": "top"
          }}>20
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>25
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>35
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>50
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>150
          </td>
        </tr>
        <tr className="odd-row">
          <td>Unfriendly
          </td>
          <td>Less than 5 </td>
          <td style={{
            "verticalAlign": "top"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>15
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>25
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>40
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>120
          </td>
        </tr>
        <tr>
          <td>Indifferent
          </td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Less
than 1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>15
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>30
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>90
          </td>
        </tr>
        <tr className="odd-row">
          <td>Friendly
          </td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Less
than 1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>20
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>60
          </td>
        </tr>
        <tr>
          <td>Helpful
          </td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Less
than 1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>50
          </td>
        </tr>
      </tbody>
    </table>
    <span style={{
      "fontWeight": "bold"
    }}>Hos:</span>
hostile. <span style={{
      "fontWeight": "bold"
    }}>Unf:</span>
unfriendly. <span style={{
      "fontWeight": "bold"
    }}>Indif:</span>
indifferent. <span style={{
      "fontWeight": "bold"
    }}>Friend:</span>
friendly. <span style={{
      "fontWeight": "bold"
    }}>Help:</span>
helpful. 
    <p><span style={{
        "fontWeight": "bold"
      }}>Fanatic:</span>
The attitude
of fanatic is added here. In addition to the obvious effects, any NPC
whose attitude is fanatic gains a +2 morale bonus to Strength and
Constitution scores, a +1 morale bonus on Will saves, and a -1 penalty
to AC whenever fighting for the character or his or her cause. This
attitude will remain for one day plus one day per point of the
character&rsquo;s Charisma bonus, at which point the
NPC&rsquo;s attitude will
revert to its original attitude (or indifferent, if no attitude is
specified). </p>
    <p>Treat the fanatic
attitude as a mind-affecting enchantment
effect for purposes of immunity, save bonuses, or being detected by the
Sense Motive skill. Since it is nonmagical, it can&rsquo;t be
dispelled;
however, any effect that suppresses or counters mind-affecting effects
will affect it normally. A fanatic NPC&rsquo;s attitude
can&rsquo;t be further
adjusted by the use of skills.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Attitude
          </th>
          <th style={{
            "width": "100px"
          }}>Means
          </th>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Possible Actions</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>Fanatic </td>
          <td>Will give life to
serve you&nbsp; </td>
          <td style={{
            "verticalAlign": "top"
          }}>Fight
to the death against
overwhelming odds, throw self in front of onrushing dragon</td>
        </tr>
      </tbody>
    </table>
    <h6><a id="disable-device"></a>Disable Device </h6>
    <p>The character can rush
his or her Disable Device attempt,
reducing the amount of time it takes to perform the attempt.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Reduce
to
          </th>
          <th style={{
            "width": "100px"
          }}>DC
Modifier
          </th>
        </tr>
        <tr className="odd-row">
          <td>1 round
          </td>
          <td>+20
          </td>
        </tr>
        <tr>
          <td>Move-equivalent
action
          </td>
          <td>+50
          </td>
        </tr>
        <tr className="odd-row">
          <td>Free action
          </td>
          <td>+100
          </td>
        </tr>
      </tbody>
    </table>
    <h6><a id="disguise"></a>Disguise </h6>
    <p>The character can change
his or her apparent height and weight
much more than normal.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Diguise
          </th>
          <th style={{
            "width": "100px"
          }}>Modifier
          </th>
        </tr>
        <tr className="odd-row">
          <td>Change height
and/or weight 11% to 25% </td>
          <td>-25* </td>
        </tr>
        <tr>
          <td>Change height
and/or weight 26% to 50% </td>
          <td>-50* </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="2">*<span style={{
              "fontStyle": "italic"
            }}>Can be negated by any
effect that can
duplicate a height </span>
            <span style={{
              "fontStyle": "italic"
            }}>and/or
weight change of
this degree.</span> </td>
        </tr>
      </tbody>
    </table>
    <h6><a id="escape-artist"></a>Escape Artist </h6>
    <p>The character can pass
through spaces smaller than his or her
head, or even through a wall of force.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Extremely tight
space </td>
          <td>80
          </td>
        </tr>
        <tr>
          <td>Pass through wall
of force </td>
          <td>120
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Extremely Tight Space:</span>
This is the DC for getting through a space when one&rsquo;s head
shouldn&rsquo;t
even be able to fit; this can be as small as 2 inches square for
Medium-size creatures. Halve this limit for each size category less
than Medium-size; double it for each size category greater than
Medium-size. If the space is long, such as in a chimney, multiple
checks may be called for. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Pass through Wall of Force:</span>
This allows a character to find a gap of weakness in a wall of force
(or similar force effect) and squeeze through it. </p>
    <h6><a id="forgery"></a>Forgery </h6>
    <p>The character can forge
handwriting he or she haven&rsquo;t even
seen.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Condition </th>
          <th style={{
            "width": "180px"
          }}>Reader&rsquo;s
Check Modifier&nbsp; </th>
        </tr>
        <tr className="odd-row">
          <td>Forge document
without sample </td>
          <td style={{
            "width": "180px"
          }}>+50
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Forge Document without
Sample:</span>&nbsp;
The character can forge a document without having seen a similar
document or having a sample of the handwriting to be copied.</p>
    <h6><a id="gather-information"></a>Gather Information </h6>
    <p>The character can gather
information without eliciting
suspicion.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "120px"
          }}>Check
Modifier
          </th>
        </tr>
        <tr className="odd-row">
          <td>Avoid suspicion </td>
          <td style={{
            "width": "120px"
          }}>-20
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Avoid Suspicion:</span>
By
accepting a -20 penalty on his or her Gather Information check, a
character can avoid any suspicions that might otherwise be aroused by
someone pursuing sensitive information. </p>
    <h6><a id="handle-animal"></a>Handle Animal </h6>
    <p>The character can handle
creatures other than animals, and can
teach or train in much less time than normally required.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Time</span>
          </td>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Rear magical beast </td>
          <td style={{
            "verticalAlign": "top"
          }}>1
year
          </td>
          <td>30 + HD of magical
beast </td>
        </tr>
        <tr>
          <td>Train magical
beast </td>
          <td style={{
            "verticalAlign": "top"
          }}>2
months
          </td>
          <td>40 + HD of magical
beast </td>
        </tr>
        <tr className="odd-row">
          <td>Rear vermin </td>
          <td style={{
            "verticalAlign": "top"
          }}>6
months
          </td>
          <td>35 + HD of vermin </td>
        </tr>
        <tr>
          <td>Train vermin </td>
          <td style={{
            "verticalAlign": "top"
          }}>2
months
          </td>
          <td>50 + HD of vermin
&nbsp; </td>
        </tr>
        <tr className="odd-row">
          <td>Rear other
creature </td>
          <td style={{
            "verticalAlign": "top"
          }}>Varies
          </td>
          <td>40 + HD of
creature </td>
        </tr>
        <tr>
          <td>Train other
creature </td>
          <td style={{
            "verticalAlign": "top"
          }}>2
months
          </td>
          <td>60 + HD of
creature </td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "250px"
          }}>Reduce
Teaching/Training to . . . </th>
          <th style={{
            "width": "100px"
          }}>DC
Modifier
          </th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "250px"
          }}>1
month
          </td>
          <td>+25
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "250px"
          }}>1
day
          </td>
          <td>+50
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "250px"
          }}>1
hour
          </td>
          <td>+75
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "250px"
          }}>1
minute
          </td>
          <td>+100
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Reduce Teaching/Training:</span>
Normally, teaching or training a creature requires two months of time.
A character can accelerate the process of teaching or training a
creature, reducing the time required to the listed time, by adding the
DC modifier to the base DC for teaching or training the creature. A
character can&rsquo;t reduce the required time to less than 1
minute. </p>
    <h6><a id="heal"></a>Heal </h6>
    <p>The character can greatly
speed a patient&rsquo;s recovery of hit
points.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Quicken recovery </td>
          <td>50
          </td>
        </tr>
        <tr>
          <td>Perfect recovery </td>
          <td>100
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Quicken Recovery:</span>
The
character can allow a character to regain hit points in a single hour
as if he or she had provided long-term care for a full day (2 or 3 hit
points per level, based on activity). The character can quicken the
recovery of up to six patients at a time. No character&rsquo;s
recovery can
be quickened more than once per day (even by different healers). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Perfect Recovery:</span>
The
character can allow a character to regain hit points in a single hour
as if he or she had provided long-term care for a full week (2 or 3 hit
points per level per day, based on activity). The character can use
perfect recovery on up to six patients at a time. No
character&rsquo;s
recovery can be perfected more than once per day, nor can perfect
recovery and quicken recovery both be used on the same patient in the
same day (even by different healers).</p>
    <h6><a id="hide"></a>Hide </h6>
    <p>The character can hide
others as well as hiding him or herself.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "110px"
          }}>Check
Modifier
          </th>
        </tr>
        <tr className="odd-row">
          <td>Hide another </td>
          <td style={{
            "width": "110px"
          }}>-30
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Hide Another:</span>
By
accepting a -30 penalty on his or her Hide check, a character can hide
another adjacent creature whose size is no more than one category
larger than the character&rsquo;s own. Modifiers to the check for
the size of
the creature still apply, as do all other penalties, including those
for moving faster than half speed. Likewise, a character can only hide
another creature when it is not under direct observation by a third
party. The creature the character hides remains hidden until it is
spotted or it takes some other action that breaks its concealment, as
normal. </p>
    <h6><a id="intimidate"></a>Intimidate </h6>
    <p>Intimidate does not
possess an epic usage. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span>
The DC to
intimidate any creature whose attitude is fanatic is increased by +20. </p>
    <h6><a id="jump"></a>Jump </h6>
    <p>Jump does not possess an
epic usage. </p>
    <h6><a id="knowledge"></a>Knowledge</h6>
    <p>Knowledge does not
possess an epic usage. </p>
    <h6><a id="listen"></a>Listen </h6>
    <p>The character can
pinpoint the location of an invisible
creature, or detect an illusion with an auditory component.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Defeat illusion
with auditory component </td>
          <td>80
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Defeat Illusion:</span>
The
character can automatically detect any illusion with an auditory
component for what it truly is. No Will save is required, and the
character doesn&rsquo;t have to interact with the illusion (but he
or she
must be able to hear its auditory component). Special: A character can
use Listen to notice the presence of an invisible creature (generally
opposed by a Move Silently check). If the character beats the DC by 20
or more, he or she can pinpoint the location of the invisible creature,
though it still maintains total concealment from the character (50%
miss chance). </p>
    <h6><a id="move-silently"></a>Move Silently </h6>
    <p>Move Silently does not
possess an epic usage. </p>
    <h6><a id="open-lock"></a>Open Lock </h6>
    <p>The character can open
locks more quickly than normal.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Open
lock as . . . </th>
          <th style={{
            "width": "100px"
          }}>DC
Modifier
          </th>
        </tr>
        <tr className="odd-row">
          <td>Move-equivalent
action </td>
          <td>+20
          </td>
        </tr>
        <tr>
          <td>Free action </td>
          <td>+50
          </td>
        </tr>
      </tbody>
    </table>
    <h6><a id="perform"></a>Perform </h6>
    <p>The character can sway an
audience&rsquo;s attitude with his or her
performance.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>
          </th>
          <th rowSpan="1" colSpan="6" style={{
            "width": "100px"
          }}>------New Attitude
------ </th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "fontWeight": "bold"
          }}>Initial
Attitude </td>
          <td style={{
            "fontWeight": "bold"
          }}>Hos
          </td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Unf
          </td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Indif
          </td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Friend
          </td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Help 
          </td>
          <td style={{
            "verticalAlign": "top",
            "fontWeight": "bold"
          }}>Fanatic
          </td>
        </tr>
        <tr>
          <td>Hostile
          </td>
          <td>Less than 20 </td>
          <td style={{
            "verticalAlign": "top"
          }}>20
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>25
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>35
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>50
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>150
          </td>
        </tr>
        <tr className="odd-row">
          <td>Unfriendly
          </td>
          <td>Less than 5 </td>
          <td style={{
            "verticalAlign": "top"
          }}>5
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>15
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>25
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>40
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>120
          </td>
        </tr>
        <tr>
          <td>Indifferent
          </td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Less
than 1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>15
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>30
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>90
          </td>
        </tr>
        <tr className="odd-row">
          <td>Friendly
          </td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Less
than 1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>20
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>60
          </td>
        </tr>
        <tr>
          <td>Helpful
          </td>
          <td>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>-
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>Less
than 1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>1
          </td>
          <td style={{
            "verticalAlign": "top"
          }}>50
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Hos:</span>
hostile. <span style={{
        "fontWeight": "bold"
      }}>Unf:</span>
unfriendly. <span style={{
        "fontWeight": "bold"
      }}>Indif:</span>
indifferent. <span style={{
        "fontWeight": "bold"
      }}>Friend:</span>
friendly. <span style={{
        "fontWeight": "bold"
      }}>Help:</span>
helpful.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Fanatic:</span>
The attitude
of fanatic is added here. In addition to the obvious effects, any NPC
whose attitude is fanatic gains a +2 morale bonus to Strength and
Constitution scores, a +1 morale bonus on Will saves, and a -1 penalty
to AC whenever fighting for the character or his or her cause. This
attitude will remain for one day plus one day per point of the
character&rsquo;s Charisma bonus, at which point the
NPC&rsquo;s attitude will
revert to its original attitude (or indifferent, if no attitude is
specified). </p>
    <p>Treat the fanatic
attitude as a mind-affecting enchantment
effect for purposes of immunity, save bonuses, or being detected by the
Sense Motive skill. Since it is nonmagical, it can&rsquo;t be
dispelled;
however, any effect that suppresses or counters mind-affecting effects
will affect it normally. A fanatic NPC&rsquo;s attitude
can&rsquo;t be further
adjusted by the use of skills. </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Attitude
          </th>
          <th style={{
            "width": "100px"
          }}>Means
          </th>
          <td style={{
            "verticalAlign": "top"
          }}><span style={{
              "fontWeight": "bold"
            }}>Possible Actions</span>
          </td>
        </tr>
        <tr className="odd-row">
          <td>Fanatic </td>
          <td>Will give life to
serve you&nbsp; </td>
          <td style={{
            "verticalAlign": "top"
          }}>Fight
to the death against
overwhelming odds, throw self in front of onrushing dragon</td>
        </tr>
      </tbody>
    </table>
    <h6><a id="profession"></a>Profession </h6>
    <p>Profession does not
possess an epic usage. </p>
    <h6><a id="ride"></a>Ride </h6>
    <p>The character can stand
upon his or her mount in combat.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Stand on mount </td>
          <td>40
          </td>
        </tr>
        <tr>
          <td>Unconscious
control </td>
          <td>50
          </td>
        </tr>
        <tr className="odd-row">
          <td>Attack from cover </td>
          <td>60
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Stand on Mount:</span>
This
allows the character to stand on his or her mount&rsquo;s back even
during
movement or combat. The character takes no penalties to actions while
doing so. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Unconscious Control:</span>
As a free action, the character can attempt to control a light horse,
pony, or heavy horse while in combat. If the character fails, he or she
controls the mount as a move-equivalent action. A character does not
need to roll for warhorses or warponies. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Attack from Cover:</span>
The
character can react instantly to drop down and hang alongside his or
her mount, using it as one-half cover. The character can attack and
cast spells while using his or her mount as cover without penalty. If
the character fails, he or she doesn&rsquo;t get the cover benefit. </p>
    <h6><a id="search"></a>Search </h6>
    <p>The character can sense
the presence of magic in an area. </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Sense magic
          </td>
          <td>60
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Sense Magic:</span>
The
character senses the presence of any active magical effects in the area
being searched. The character can&rsquo;t determine the number,
strength, or
type of the effects. </p>
    <h6><a id="sense-motive"></a>Sense Motive </h6>
    <p>The character can discern
a target&rsquo;s alignment and even detect
surface thoughts.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Discern partial
alignment </td>
          <td>60
          </td>
        </tr>
        <tr>
          <td>Discern full
alignment </td>
          <td>80
          </td>
        </tr>
        <tr className="odd-row">
          <td>Detect surface
thoughts </td>
          <td>100
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Discern Partial Alignment:</span>
This use of the skill lets a character discern one component of a
target&rsquo;s alignment. When making the check, the character
states whether
he or she is trying to discern the law-chaos or good-evil component. A
character can&rsquo;t retry the check, and he or she
can&rsquo;t use this to
discern more than one component of the alignment (but see below). The
target must be visible and within 30 feet of the character. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Discern Full Alignment:</span>
This use of the skill lets a character determine both components of a
target&rsquo;s alignment. The character can&rsquo;t retry the
check. The target
must be visible and within 30 feet of the character. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Detect Surface Thoughts:</span>
This lets a character read the surface thoughts of a single target (as
the 3rd-round effect of the detect thoughts spell). There is no saving
throw to resist this effect, though the target can use Bluff to
disguise his or her surface thoughts (see the Bluff skill description),
in which case this becomes an opposed check (any result lower than 100
automatically fails). The target must be visible and within 30 feet of
the character.</p>
    <h6><a id="sleight-of-hand"></a>Sleight of Hand </h6>
    <p>The character can lift
another&rsquo;s weapon and perform major
feats of legerdemain.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Lift a sheathed
weapon from another creature and hide
it on the
character&rsquo;s person, if the weapon is no more than one size
category
larger than the character&rsquo;s own size.
&nbsp;&nbsp;&nbsp; </td>
          <td>50
          </td>
        </tr>
        <tr>
          <td>Make an adjacent,
willing creature or object of the
character&rsquo;s size or
smaller &ldquo;disappear&rdquo; while in plain view. In fact,
the willing creature
or object is displaced up to 10 feet away-make a separate Hide check to
determine how well the &ldquo;disappeared&rdquo; creature or
object is hidden.
&nbsp;&nbsp;&nbsp; </td>
          <td>80
          </td>
        </tr>
      </tbody>
    </table>
    <h6><a id="spellcraft"></a>Spellcraft </h6>
    <p>The character can
identify properties of a magic item.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Identify basic
property of magic item </td>
          <td>50 + caster level </td>
        </tr>
        <tr>
          <td>Identify all
properties of magic item </td>
          <td>70 + caster level </td>
        </tr>
        <tr className="odd-row">
          <td>Quick
identification of alchemical substances and
potions </td>
          <td>50
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Identify Basic Property of
Magic Item:</span> This use of the
skill requires one round of
inspection, and functions exactly as if the character had cast an
identify spell on the item. A character can&rsquo;t attempt this on
the same
item more than once. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Identify All Properties of
Magic Item:</span> This requires one
minute of inspection, and reveals
all properties of a single magic item (including command words and
charges remaining). A character can&rsquo;t attempt this on the
same item
more than once. If an item has different caster levels for different
properties, use the highest caster level. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Quick Identification:</span>
A character can identify a substance or potion in the field as a
full-round action, without an alchemical lab or any cost. The character
can&rsquo;t retry this check (or take 20); if he or she fails, the
character
must identify the substance in an alchemical lab, as normal. </p>
    <h6><a id="spot"></a>Spot </h6>
    <p>The character can
pinpoint the location of an invisible
creature, or detect an illusion with a visual component.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Notice presence of
active invisible creature </td>
          <td>20
          </td>
        </tr>
        <tr>
          <td>Notice presence of
unmoving, living invisible creature
&nbsp;&nbsp;&nbsp; </td>
          <td>30
          </td>
        </tr>
        <tr className="odd-row">
          <td>Notice presence of
inanimate invisible object &nbsp; </td>
          <td>40
          </td>
        </tr>
        <tr>
          <td>Notice presence of
unmoving, unliving invisible
creature </td>
          <td>40
          </td>
        </tr>
        <tr className="odd-row">
          <td>Defeat illusion </td>
          <td>80
          </td>
        </tr>
      </tbody>
    </table>
    <p>A character can use Spot
to notice the presence of an
invisible creature. The relevant DCs are reprinted here. If the
character beats the DC by 20 or more, he or she can pinpoint the
location of the invisible creature, though it still maintains total
concealment from the character (50% miss chance). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Defeat Illusion:</span>
The
character can automatically detect any illusion with a visual component
for what it truly is. No Will save is required, and the character
doesn&rsquo;t have to interact with the illusion (but he or she
must be able
to see it). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Using Spot to Read Lips</span>
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
Modifier
          </th>
        </tr>
        <tr className="odd-row">
          <td>Read lips while
moving at up to full speed </td>
          <td>+20
          </td>
        </tr>
        <tr>
          <td>Pronounce
unfamiliar language </td>
          <td>+20
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Pronounce Unfamiliar
Language: </span>This use of the
skill allows a character to repeat
the speech of an observed creature, potentially allowing a comrade to
translate the speech. It doesn&rsquo;t grant the character any
ability to
understand the language spoken. </p>
    <h6><a id="survival"></a>Survival</h6>
    <p>The character can ignore
the effects of terrain on movement
and withstand even the harshest weather. If the character is capable of
tracking, he or she can identify the races of creatures being tracked.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Get along in the
wild while moving at full speed. The
character can
provide food and water for one other person for every 2 points by which
the check result exceeds 40. &nbsp;&nbsp; </td>
          <td>40
          </td>
        </tr>
        <tr>
          <td>Automatically
succeed on all Fortitude saves against
severe weather.
The character can extend this benefit to one other character for every
2 points by which the check result exceeds 60. &nbsp;&nbsp; 
          </td>
          <td>60
          </td>
        </tr>
        <tr className="odd-row">
          <td>Ignore overland
movement penalties of terrain. The
character and his or
her mount can move at full overland speed regardless of terrain. The
character can extend this benefit to one other character for every 5
points by which the check result exceeds 60.
&nbsp;&nbsp;&nbsp; </td>
          <td>60
          </td>
        </tr>
        <tr>
          <td>Identify race/kind
of creature(s) by tracks.* </td>
          <td>60
          </td>
        </tr>
        <tr>
          <td style={{
            "verticalAlign": "top"
          }} rowSpan="1" colSpan="2">*<span style={{
              "fontStyle": "italic"
            }}>Requires the Track feat. </span></td>
        </tr>
      </tbody>
    </table>
    <p>Wherever the character
is, he or she can determine the
direction to a location on the same plane.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "200px"
          }}>Familiarity
with Location </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "200px"
          }}>Very
familiar </td>
          <td>40
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "200px"
          }}>Studied
carefully </td>
          <td>60
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "200px"
          }}>Seen
casually </td>
          <td>80
          </td>
        </tr>
        <tr>
          <td style={{
            "width": "200px"
          }}>Viewed
once </td>
          <td>100
          </td>
        </tr>
        <tr className="odd-row">
          <td style={{
            "width": "200px"
          }}>Description
only </td>
          <td>120
          </td>
        </tr>
      </tbody>
    </table>
    <p>With a successful check,
the character knows the direction to
the desired location. This merely points the character in the direction
of the location; it doesn&rsquo;t provide him or her with
information on how
to get there, nor does it take into account any obstacles in the path.
&ldquo;Very familiar&rdquo; represents a place where the
character has been very
often and where he or she feels at home. &ldquo;Studied
carefully&rdquo; represents
a place the character knows well, either because he or she has been
there often or has used other means to study the place. &ldquo;Seen
casually&rdquo;
is a place that the character has viewed more than once, but which he
or she has not studied. &ldquo;Viewed once&rdquo; is a place
that the character has
seen once, possibly using magic. &ldquo;Description only&rdquo;
is a place whose
location and appearance the character knows through someone
else&rsquo;s
description. </p>
    <h6><a id="swim"></a>Swim </h6>
    <p>The character can swim up
vertical surfaces, or swim much
faster than normal.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Swim up waterfall </td>
          <td>80
          </td>
        </tr>
      </tbody>
    </table>
    <span style={{
      "fontWeight": "bold"
    }}>Swim
up Waterfall:</span> This
use of the skill allows a character to swim an angled or vertical
surface, as long as he or she remains completely or mostly immersed in
water. Other examples might include swimming up a whirlpool or an
incredibly large wave. 
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Circumstance </th>
          <th style={{
            "width": "120px"
          }}>Check
Modifier
          </th>
        </tr>
        <tr className="odd-row">
          <td>Speed swimming
          </td>
          <td style={{
            "width": "120px"
          }}>-20
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Speed Swimming:</span>
By
taking a -20 penalty on the check, a character can swim his or her
speed as a move-equivalent action, or double his or her speed as a
full-round action. </p>
    <h6><a id="tumble"></a>Tumble </h6>
    <p>The character can fall
from great heights without taking
damage, move greater distances with an adjustment, or
&ldquo;climb&rdquo; vertical
surfaces with a series of bounces.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Treat a fall as if
it were 20 feet shorter when
determining damage. </td>
          <td>30
          </td>
        </tr>
        <tr>
          <td>Free stand. </td>
          <td>35
          </td>
        </tr>
        <tr className="odd-row">
          <td>Treat a fall as if
it were 30 feet shorter when
determining damage. </td>
          <td>45
          </td>
        </tr>
        <tr>
          <td>Climb vertical
surface. </td>
          <td>50
          </td>
        </tr>
        <tr className="odd-row">
          <td>Treat a fall as if
it were 40 feet shorter when
determining damage. </td>
          <td>60
          </td>
        </tr>
        <tr>
          <td> Ignore falling
damage. </td>
          <td>100
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Free Stand:</span>
The
character can stand up from prone as a free action (instead of as a
move-equivalent action). </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Climb Vertical Surface:</span>
The character can climb up to 20 feet (as part of normal move-ment) by
jumping and bouncing off walls, trees, or similar vertical surfaces.
The character must have at least two vertical surfaces to bounce off,
and the two must be within 10 feet of each other. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Ignore Falling Damage:</span>
The character can fall from any height and take no damage. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span>
A character
with 25 or more ranks in Tumble gains a +5 dodge bonus when executing
the fight defensively standard or full-round action, instead of a +2
bonus (or the +3 bonus from having 5 or more ranks). Increase this
dodge bonus by +1 for every 10 additional ranks above 25 the character
has. A character with 25 or more ranks in Tumble gains a +10 dodge
bonus to AC when executing the total defense standard action, instead
of a +4 bonus (or the +6 bonus from having 5 or more ranks). Increase
this dodge bonus by +2 for every 10 additional ranks above 25 the
character has. </p>
    <h6><a id="use-magic-device"></a>Use Magic Device </h6>
    <p>Use Magic Device does not
possess an epic usage. </p>
    <h6><a id="use-rope"></a>Use Rope </h6>
    <p>The character can splice
ropes together quickly, tie unique
knots, and even animate a rope he or she holds.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Quick splicing </td>
          <td>50
          </td>
        </tr>
        <tr>
          <td>Tie unique knot </td>
          <td>60
          </td>
        </tr>
        <tr className="odd-row">
          <td>Animate held rope </td>
          <td>80
          </td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Quick Splicing:</span>
The
character can splice two ropes together as a move-equivalent action. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Tie Unique Knot:</span>
The
character can tie a knot that only he or she knows how to untie. This
doesn&rsquo;t affect any Escape Artist checks made to escape these
bindings. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Animate Held Rope:</span>
The
character can command any rope he or she holds as if it had the animate
rope spell cast upon it (except that using the skill in this way
doesn&rsquo;t grant any bonus on Use Rope checks made with the
animated
rope). Each command requires a separate Use Rope check. Because the
effect isn&rsquo;t magical, it can&rsquo;t be dispelled.
    </p>
    <a id="epic-psionic-skills"></a><h5>PSIONIC SKILLS</h5>
    <h6><a id="autohypnosis"></a>Autohypnosis </h6>
    <p>The character has trained
his or her mind to ignore poison,
resist mental influence, and convince his or her body that he or she is
tougher than normal.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>When poisoned, the
character can make an Autohypnosis
check on his or
her next action. A successful check indicates the character does not
have to make a saving throw against the poison&rsquo;s secondary
damage,
which he or she instead automatically ignores. &nbsp;&nbsp; 
          </td>
          <td>Poison&rsquo;s
DC + 5 
          </td>
        </tr>
        <tr>
          <td>If a failed saving
throw indicates the character is
affected by any
mind-affecting powers, spells, or spell-like effects, a successful
Autohypnosis check allows an immediate second saving throw to resist
the effect. If the mind-affecting effect normally does not allow a
saving throw, a successful Autohypnosis check allows a saving throw.
&nbsp;&nbsp; 
          </td>
          <td>50
          </td>
        </tr>
        <tr className="odd-row">
          <td>With a successful
Autohypnosis check, the character
gains temporary hit
points equal to 10 + his or her Wisdom modifier. The temporary hit
points persist until lost. A character cannot check for temporary hit
points more than once per day. Temporary hit points gained through
Autohypnosis do not stack with temporary hit points gained through any
other source &nbsp;&nbsp; 
          </td>
          <td>60
          </td>
        </tr>
      </tbody>
    </table>
    <h6><a id="stabilize-self"></a>Stabilize Self </h6>
    <p>Mortal wounds are less
lethal for the character.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "100px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>If reduced to
negative hit points but not dead, make a
Stabilize Self
check. If successful, the character does not go unconscious and can
continue taking actions until he or she bleeds to death or stabilizes
(the character can also continue making stabilization checks). If
healed or stabilized, the character continues to take actions normally.
&nbsp;&nbsp; 
          </td>
          <td>30
          </td>
        </tr>
        <tr>
          <td>On a successful
Stabilize Self check, the character
gains damage
reduction 2/-. The damage reduction lasts for 12 hours. The character
cannot check for damage reduction more than once per day. Damage
reduction gained through Stabilize Self does not stack with damage
reduction gained through any other source. &nbsp;&nbsp; 
          </td>
          <td>60
          </td>
        </tr>
      </tbody>
    </table>
    <h5>Miscellaneous</h5>
    <h6><span style={{
        "fontStyle": "italic"
      }}>Scrying</span>
spells</h6>
    <p>To attempt any of these
tasks, the <a href="spellsS.html#scrying" style={{
        "color": "rgb(87, 158, 182)"
      }}>scrying</a>
spell must be on your class spell list (though it need not be among
your spells known). If you have any bonuses that would apply to the
caster level of your scrying spell, add these to your caster level
check.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "100px"
          }}>Task
          </th>
          <th style={{
            "width": "200px"
          }}>DC
          </th>
        </tr>
        <tr className="odd-row">
          <td>Learn scryer
          </td>
          <td style={{
            "width": "200px"
          }}>30
          </td>
        </tr>
        <tr>
          <td>Return scrying
          </td>
          <td style={{
            "width": "200px"
          }}>50
          </td>
        </tr>
        <tr className="odd-row">
          <td>Break scrying </td>
          <td style={{
            "width": "200px"
          }}>Opposed
caster level check</td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontWeight": "bold"
      }}>Learn Scryer:</span>
If you
determine that you&rsquo;re being scried upon, you can learn the
identity of
the scryer with a DC 30 caster level check. If successful, you learn
the name, race, and location of the scryer. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Return Scrying:</span>
If you
determine that you&rsquo;re being scried upon, you can look back
through the
sensor at the scryer with a DC 50 caster level check. This allows you
to spy on the scrying being as if you had cast a scrying spell upon
that person. This return scrying can be detected by your target as
normal.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Break Scrying:</span>
If you
determine that you&rsquo;re being scried, you can make an opposed
caster
level check with the scryer. (Your caster level must be at least 21st
to attempt this task.) If you are successful, the scrying ends, and the
scryer may not target you with a Divination (Scrying) spell or effect
for a number of hours equal to your Intelligence modifier (minimum 1</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      